import { Component,EventEmitter, Input, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, MemberService } from '../../services';

@Component({
  selector: 'app-retro-claim-points',
  templateUrl: './retro-claim-points.component.html',
  styleUrls: ['./retro-claim-points.component.less']
})
export class RetroClaimPointsComponent implements OnInit {
  isButtonClicked = false;
  modalRef: any;
  external_id: any;
  memberId:any;
  @Input() retroClaimData: any;
  @ViewChild('modaloader')
    private modalloader: TemplateRef<any>;
  constructor(	private modalService: NgbModal,
    private commonService: CommonService,
    private memberService: MemberService,) { }
  showPopup(modalid, modalclass) {
		this.open(modalid, modalclass)
	}
  open(content, modalclass) {
		this.modalRef = this.modalService.open(content, { windowClass: modalclass });
	}
  ngOnInit() {
    console.log('retroClaimData',this.retroClaimData);
      this.memberId=localStorage.getItem('member_id');
  }
  handleButtonClick() {
    this.isButtonClicked = !this.isButtonClicked;
  }
  close() {
		this.modalService.dismissAll();
	}
  
  retroClaimPointsData:any;
  record_id:any;
  retroClaimTransaction(){
    this.record_id=this.retroClaimData[0].id;
    let req = {
      member_id: this.memberId,
      record_ids: [this.retroClaimData[0].id],
      skip_all_validation:false,
      user_input: {
        [this.retroClaimData[0].id]: {
          point_earn_preference: "JH"
        }
      }
    };
    this.memberService
      .retroClaimTransaction(req)
      .then((data: any) => {
        this.retroClaimPointsData = data;
        setTimeout(() => {
          this.open(this.modalloader,'modaloader');
        }, 400);
      })
      .catch(error => {
        
      });
  }

}
